import { captureRemixErrorBoundaryError } from "@sentry/remix";
// Import styles of packages that you've installed.
// All packages except `@mantine/hooks` require styles imports
import "@mantine/core/styles.css";
import "@mantine/dates/styles.css";
import "@mantine/notifications/styles.css";
import stylesheet from "~/tailwind.css?url";

import { ColorSchemeScript, MantineProvider, createTheme } from "@mantine/core";
import { Notifications } from "@mantine/notifications";
import type { LinksFunction, LoaderFunction } from "@remix-run/node";
import { json } from "@remix-run/node";
import {
	Links,
	Meta,
	Outlet,
	Scripts,
	ScrollRestoration,
	useLoaderData,
	useRouteError,
} from "@remix-run/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

export const loader: LoaderFunction = () => {
	return json({
		SENTRY_DSN: process.env.SENTRY_DSN,
	});
};

export const links: LinksFunction = () => [
	{ rel: "stylesheet", href: stylesheet },
];

const themeOverride = createTheme({
	colors: {
		"tm-blue": [
			"#093c71",
			"#093c71",
			"#093c71",
			"#093c71",
			"#093c71",
			"#093c71",
			"#093c71",
			"#093c71",
			"#093c71",
			"#093c71",
		],
		"tm-green": [
			"#27b779",
			"#27b779",
			"#27b779",
			"#27b779",
			"#27b779",
			"#27b779",
			"#27b779",
			"#27b779",
			"#27b779",
			"#27b779",
		],
	},
});

const queryClient = new QueryClient();

export function Layout({ children }: { children: React.ReactNode }) {
	const env = useLoaderData<typeof loader>();
	return (
		<html lang="en">
			<head>
				<meta charSet="utf-8" />
				<meta name="viewport" content="width=device-width, initial-scale=1" />
				<Meta />
				<Links />
				<ColorSchemeScript />
				<script
					// biome-ignore lint/security/noDangerouslySetInnerHtml: add sentry dsn
					dangerouslySetInnerHTML={{
						__html: `window.ENV = ${JSON.stringify(env)}`,
					}}
				/>
			</head>
			<body className="h-screen">
				<QueryClientProvider client={queryClient}>
					<MantineProvider
						withCssVariables
						withGlobalClasses
						withStaticClasses
						theme={themeOverride}
					>
						<Notifications position="top-center" />
						{children}
					</MantineProvider>
					<ScrollRestoration />
					<Scripts />
					<ReactQueryDevtools initialIsOpen={false} />
				</QueryClientProvider>
			</body>
		</html>
	);
}

export const ErrorBoundary = () => {
	const error = useRouteError();
	captureRemixErrorBoundaryError(error);
	return <div>Something went wrong</div>;
};

export default function App() {
	return <Outlet />;
}
